import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { Layout } from '../../Layout';
import { Container } from '../../styles/pages/Expedicion.styles';

const Exposicion = () => {
  return (
    <Layout title="Exposición">
      <Container>
        <StaticImage
          src="../../images/expo/1.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <p>
          Nuestro objetivo es producir nuevos documentos, como también nuevos
          debates respecto a la historia de Chile.
        </p>
        <p>
          Las Fotografías originales registradas (placas de vidrio imprimadas en
          gelatino de bromuro) serán donadas al museo Martín Gusinde y Fundación
          Hach Saye, en la Región de Magallanes. Adicionalmente realizaremos
          ponencias y otras actividades de difusión por medio del festival de
          artes Cielos del Infinito.
        </p>

        <p>
          Finalmente, se encuentra ad-portas de inaugurarse una exposición que
          contribuya a la exhibición permanente del lugar, de manera de
          enriquecer el patrimonio histórico del Museo por medio de una mirada
          contemporánea de aquellos documentos.
        </p>

        <hr />

        <StaticImage
          src="../../images/expo/expo.jpg"
          alt="imagen"
          placeholder="blurred"
        />
      </Container>
    </Layout>
  );
};

export default Exposicion;
